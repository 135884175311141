<template>
  <div class="page">
    <div class="page-content">
      <div class="page-header">
        <div class="page-title">Скрипты</div>
        <div
          class="page-header-button default-button"
          @click="$router.push({name: 'Script', params: {scriptId: 'new'}})"
        >
          <div class="icon icon-new"></div>
          <span>Новый скрипт</span>
        </div>
      </div>
      <div class="script-list" v-if="loading">
        <ul>
          <li v-for="i in 3" :key="i">
            <v-skeleton-loader type="list-item-avatar-two-line"/>
          </li>
        </ul>
      </div>
      <div class="script-list" v-else>
        <div class="empty-page" v-if="!scripts.length">
          <img class="empty-page_icon" src="@/images/script-picture.svg" alt="script icon">
          <NoResults>
            <template v-slot:text>
              Добавьте заготовки вопросов – так будет проще проводить собеседование
            </template>
          </NoResults>
          <div
            class="default-button create-script-button"
            @click="$router.push({name: 'Script', params: {scriptId: 'new'}})"
          >
            <div class="icon icon-new"></div>
            <span>Новый скрипт</span>
          </div>
        </div>
        <ul v-else>
          <li v-for="script in scripts" :key="script.id">
            <div
              class="script-list_item"
              @click="$router.push({name: 'Script', params: {scriptId: script.id}})"
            >
              <div class="script-list_item_media">
                <div class="icon icon-script"></div>
              </div>
              <div class="script-list_item_inner">
                <div class="script-list_item_title">{{ script.title }}</div>
                <div class="script-list_item_subtitle">
                  <template v-if="script.questions && script.questions.length">
                    {{ script.questions.length }}
                    {{ declOfNum(script.questions.length, 'вопрос, вопроса, вопросов') }}
                  </template>
                  <template v-else>
                    Нет вопросов
                  </template>
                </div>
              </div>
              <div class="script-list_item_after">
                <div class="icon icon-trash" @click.stop="removeScriptConfirmOpen(script.id)"></div>
                <div class="icon icon-pencil"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Confirm ref="confirm-remove" @accept="removeScript" message="Вы уверены, что хотите удалить Скрипт?"
             cancel-text="Нет" accept-text="Да"
    />
  </div>
</template>

<script>
export default {
  name: 'Scripts',
  components: {},

  data() {
    return {
      scripts: [],
      loading: false,
    }
  },
  methods: {
    removeScriptConfirmOpen(id) {
      this.scriptIdForRemoving = id;
      this.$refs['confirm-remove'].open();
    },
    removeScript() {
      this.loading = true;
      this.$server.request2(`form/remove/${this.scriptIdForRemoving}`, {}, (data)=>{
        this.loadList();
      });
    },
    loadList() {
      this.loading = true;
      return this.$server.request2('form/get', {}, (data)=>{
        this.loading = false;
        this.scripts = data.response;
      }, ()=>{
        this.loading = false;
      });
    },
  },
  mounted() {
    this.loadList();
  },
  computed: {

  },
};
</script>

<style scoped lang="scss">
.empty-page {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &::v-deep(.no-results-block) {
    height: auto;

    .no-results-block_line {
      margin-top: -60px;
      margin-bottom: -60px;
    }
  }

  .create-script-button {
    margin-top: 40px;
    width: auto;
    min-width: 180px;

    .icon {
      font-size: 12px;
      margin-right: 12px;
    }
  }
}

.script-list {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  &_title {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.5px;
  }

  &_add {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: var(--color-blue);
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      margin-right: 6px;
      font-size: 12px;
      font-weight: bold;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    border-top: 1px solid var(--border-color);
  }

  li {
    border-bottom: 1px solid var(--border-color);
  }

  &_item {
    cursor: pointer;
    display: flex;

    &_media {
      flex-shrink: 0;
      margin-right: 20px;
      padding: 12px 0;

      .icon {
        width: 54px;
        height: 54px;
        font-size: 24px;
        background: var(--border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }

    &_inner {
      width: 100%;
      min-width: 0;
      padding: 18px 0;

      & > * {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &_title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    &_subtitle {
      font-size: 14px;
      line-height: 16px;
    }

    &_after {
      flex-shrink: 0;
      display: flex;
      align-items: center;

      & > .icon {
        padding: 0 14px;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 24px;
        color: rgba(var(--page-color-main-rgb), .25);

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .no-results-block {
    margin-top: 50px;
  }
}
</style>
